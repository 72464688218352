import cn from 'classnames';
import { Entry } from 'contentful';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import { Product } from '@commerce/types/product';
import style from '@components/blogPage/BlogDetail/BlogDetail.module.scss';
import { RichTextRenderer } from '@components/common/RichText/factory';
import { mapFields } from '@components/common/RichText/helpers';
import { UnwrapEntry } from '@components/common/RichText/types';
import { ModuleProduct } from '@components/common/types/ModuleProduct';
import { getProductsBySKUs } from '@components/product/helpers';
import ProductCard from '@components/product/ProductListing/ProductCard';
import { useAddToCart } from '@lib/hooks/useAddToCart';

type RendererEntryProps = Entry<{
  internalName?: string;
  product: ModuleProduct;
  alignment?: string;
}>;

type Props = UnwrapEntry<RendererEntryProps>;

const BlogProductCard: FC<Props> = ({ product, alignment }: Props) => {
  const [, onAddToCart] = useAddToCart();
  const [productData, setProductData] = useState<Product | null>(null);
  const router = useRouter();
  const { sku: productSku } = product;

  useEffect(() => {
    async function getProduct(sku: string) {
      const products = await getProductsBySKUs([sku], router.locale);
      if (products?.length > 0) {
        setProductData(products[0]);
      }
    }

    if (productSku && !productData) {
      getProduct(productSku);
    }
  }, [productData, productSku, router.locale]);

  if (!product || !productData) {
    return null;
  }

  const imgProps = { loading: 'lazy', width: '360', height: '360' } as const;
  return (
    <div
      className={cn('mb-xs flex', {
        'justify-start': alignment === 'left',
        'justify-center': alignment === 'center',
        'justify-end': alignment === 'right',
      })}
    >
      <ProductCard className={style.productCard} product={productData!} onAddToCart={onAddToCart} imgProps={imgProps} />
    </div>
  );
};

const mapper = (entry: RendererEntryProps) => mapFields(entry);

export default RichTextRenderer({ name: 'moduleBlogProductCard', mapper })(BlogProductCard);
