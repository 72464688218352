import { FC, ReactNode } from 'react';

import style from './BadgeOverlay.module.scss';

interface Shift {
  top: number | string;
  bottom: number | string;
  left: number | string;
  right: number | string;
}

interface BadgeOverlayProps {
  TopLeft?: ReactNode;
  TopRight?: ReactNode;
  BottomLeft?: ReactNode;
  BottomRight?: ReactNode;
  children: ReactNode;
  shift?: Shift;
  className?: string;
}

function renderWrappedOverlay(overlay: ReactNode, cssStyle: string, shift: Partial<Shift>) {
  return (
    <div className={cssStyle} style={shift}>
      {overlay}
    </div>
  );
}

const BadgeOverlay: FC<BadgeOverlayProps> = ({
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
  children,
  className,
  shift = { top: 0, bottom: 0, left: 0, right: 0 },
}: BadgeOverlayProps) => {
  const { top, bottom, left, right } = shift;
  return (
    <div className={`${style.container} ${className ?? ''}`}>
      {TopLeft && renderWrappedOverlay(TopLeft, style.topLeft, { top, left })}
      {TopRight && renderWrappedOverlay(TopRight, style.topRight, { top, right })}
      {BottomLeft && renderWrappedOverlay(BottomLeft, style.bottomLeft, { bottom, left })}
      {BottomRight && renderWrappedOverlay(BottomRight, style.bottomRight, { bottom, right })}
      {children}
    </div>
  );
};

export default BadgeOverlay;
