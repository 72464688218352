import { documentToHtmlString, NodeRenderer } from '@contentful/rich-text-html-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import get from 'lodash/get';
import { TFunction } from 'next-i18next';

import { isExternalLink } from '@lib/check-external-link';
import { Nullable } from '@lib/utility-types';

const CONTENTFUL_ASSETS_HOST_REPLACEMENTS: Record<string, string> = (() => {
  try {
    const parsed = JSON.parse(process.env.CONTENTFUL_ASSETS_HOST_REPLACEMENTS as string);
    return parsed;
  } catch (err) {
    return {};
  }
})();

const TAG_FOR_NODE_TYPE = {
  [BLOCKS.PARAGRAPH]: 'p',
  [BLOCKS.HEADING_1]: 'h1',
  [BLOCKS.HEADING_2]: 'h2',
  [BLOCKS.HEADING_3]: 'h3',
  [BLOCKS.HEADING_4]: 'h4',
  [BLOCKS.HEADING_5]: 'h5',
  [BLOCKS.HEADING_6]: 'h6',
  [BLOCKS.LIST_ITEM]: 'li',
  [BLOCKS.QUOTE]: 'blockquote',
};

// Replace newlines with <br/> and quotes with <q></q>
// https://github.com/contentful/rich-text/issues/96#issuecomment-506238120
const renderTextNodeWithLineBreaks: NodeRenderer = ({ nodeType, content }, next) => {
  const tag = TAG_FOR_NODE_TYPE[nodeType as keyof typeof TAG_FOR_NODE_TYPE];
  return `<${tag}>${next(content)
    .replace(/\n/g, '<br/>')
    .replace(/&lt;br\s*\/?&gt;/g, '<br/>')
    .replace(/&quot;(.*?)&quot;/g, '<q>$1</q>')
    .replace(/“(.*?)”/g, '<q>$1</q>')}</${tag}>`;
};

export const getUrlForSlug = (slug: string, isInApp = false) => {
  const query = !isInApp ? '' : '?inapp=1';
  const logicalSlug = slug === '__home__' ? '/' : slug;
  return logicalSlug + query;
};

export const getUrlForFile = (file: { url: string }): string => {
  if (!file?.url) {
    return '';
  }
  // just need the pathname
  const parsedUrl = new URL(file.url, 'http://a');
  parsedUrl.protocol = 'https';

  if (CONTENTFUL_ASSETS_HOST_REPLACEMENTS[parsedUrl.host]) {
    parsedUrl.host = CONTENTFUL_ASSETS_HOST_REPLACEMENTS[parsedUrl.host];
  }

  return parsedUrl.href;
};

export const escapeHtml = (unsafeString: string) =>
  unsafeString
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');

const getInlineLink = (url: string, locale: string): string => {
  // url is an external link
  // or already contains locale prefix
  if (isExternalLink(url) || url.startsWith(`/${locale}`)) {
    return url;
  }

  return `/${locale}${url}`;
};

export const getLastSubstring = (str: string): string => {
  const substrings: string[] = str.split(/[-_]/);

  // Return the last substring
  return substrings[substrings.length - 1];
};

export const richTextDocumentToHtmlString = (richTextDocument: any, t: Nullable<TFunction>, locale: string) => {
  return documentToHtmlString(richTextDocument, {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description } = node.data.target.fields;
        const url = getUrlForFile(node.data.target.fields.file);
        const escapedText = escapeHtml(description || title);

        return `<img src='${url}' alt='${escapedText}' title='${escapedText}' />`;
      },

      [BLOCKS.EMBEDDED_ENTRY]: (node, next) => {
        const contentType = get(node, 'data.target.sys.contentType.sys.id');

        if (contentType === 'linkedAsset') {
          const fields = {
            target: '_self',
            title: '',
            url: '',
            ...node.data.target.fields,
          };
          const { entry, asset, target, title, url } = fields;
          const assetUrl = getUrlForFile(asset.fields.file);
          const { description } = asset.fields;
          const hrefUrl = (entry && getUrlForSlug(entry.fields.slug)) || url;
          const escapedText = escapeHtml(description || title);

          return `<a href='${hrefUrl}' target='${target}'><img src='${assetUrl}' alt='${escapedText}' title='${escapedText}' /></a>`;
        }

        return `<div>${next(node.content)}</div>`;
      },

      [INLINES.HYPERLINK]: (node, next) => {
        const { uri } = node.data;
        const title = t?.('common:screenReader.openInNewTab') ?? '';
        const linkProps = isExternalLink(uri) ? `target='_blank' title='${title}'` : `target='_self'`;
        return `<a href='${getInlineLink(uri, locale)}' ${linkProps}>${next(node.content)}</a>`;
      },

      [INLINES.ENTRY_HYPERLINK]: (node, next) => {
        const url = getUrlForSlug(node.data.target.fields.slug);

        return `<a href='${url}' target='_self'>${next(node.content)}</a>`;
      },

      [INLINES.ASSET_HYPERLINK]: (node, next) => {
        const { url } = node.data.target.fields;
        const title = t?.('common:screenReader.openInNewTab') ?? '';

        return `<a href='${url}' target='_blank' title='${title}'>${next(node.content)}</a>`;
      },

      [INLINES.EMBEDDED_ENTRY]: (node, next) => {
        const contentType = get(node, 'data.target.sys.contentType.sys.id');

        if (contentType === 'linkedAsset') {
          const fields = {
            target: '_self',
            title: '',
            url: '',
            ...node.data.target.fields,
          };
          const { entry, asset, target, title, url } = fields;
          const assetUrl = getUrlForFile(asset.fields.file);
          const hrefUrl = (entry && getUrlForSlug(entry.fields.slug)) || url;
          const { description } = asset.fields;
          const escapedText = escapeHtml(description || title);

          return `<a href='${hrefUrl}' target='${target}'><img class='inline-embedded-entry' src='${assetUrl}' alt='${escapedText}' title='${escapedText}' /></a>`;
        }

        // For Custom color text
        if (contentType === 'moduleFormattedText') {
          const { text, color } = node.data.target.fields;
          return `<span style='color:${getLastSubstring(color)}'>${text}</span>`;
        }
        return `<span>${next(node.content)}</span>`;
      },

      [BLOCKS.PARAGRAPH]: renderTextNodeWithLineBreaks,
      [BLOCKS.HEADING_1]: renderTextNodeWithLineBreaks,
      [BLOCKS.HEADING_2]: renderTextNodeWithLineBreaks,
      [BLOCKS.HEADING_3]: renderTextNodeWithLineBreaks,
      [BLOCKS.HEADING_4]: renderTextNodeWithLineBreaks,
      [BLOCKS.HEADING_5]: renderTextNodeWithLineBreaks,
      [BLOCKS.HEADING_6]: renderTextNodeWithLineBreaks,
      [BLOCKS.LIST_ITEM]: renderTextNodeWithLineBreaks,
      [BLOCKS.QUOTE]: renderTextNodeWithLineBreaks,
    },

    // Use ada-friendly tags for bold and italic
    renderMark: {
      [MARKS.BOLD]: (text) => `<strong>${text}</strong>`,
      [MARKS.ITALIC]: (text) => `<em>${text}</em>`,
    },
  });
};
