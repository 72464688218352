import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { Product, ProductImage } from '@commerce/types/product';
import { ProductType } from '@components/product/enums';
import { Text } from '@components/ui';
import StarRating from '@components/ui/StarRating/StarRating';
import { BigCommerceProductImage, ImageSize } from '@lib/bigcommerce-product-image';
import { trackEvent } from '@lib/gtag';
import { AddToCartFn } from '@lib/hooks/useAddToCart';
import { useHasL360PremiumInCart } from '@lib/hooks/useHasL360PremiumInCart';
import { cleanQueryParams } from '@lib/http';
import { ImageOptions } from '@lib/image';

import Badge from '../../Badge';
import BadgeOverlay from '../../BadgeOverlay';
import { getProductInfo } from '../../helpers';
import Pricing from '../../Pricing/Pricing';

import style from './ProductCard.module.scss';

interface Props {
  className?: string;
  product: Product;
  imgProps?: ImageOptions;
  'data-cy'?: string;
  onAddToCart?: AddToCartFn;
  showProductDescriptionMobile?: boolean;
  showProductDescriptionDesktop?: boolean;
}

const getDescriptionClassName = (showOnMobile?: boolean, showOnDesktop?: boolean): string => {
  if (!showOnMobile && !showOnDesktop) {
    return 'hidden';
  }

  if (showOnMobile && !showOnDesktop) {
    return 'md:hidden';
  }

  if (!showOnMobile && showOnDesktop) {
    return 'hidden md:block';
  }

  return '';
};

const Rating: FC<{ summationOfRatings: number; numberOfReviews: number }> = ({
  summationOfRatings,
  numberOfReviews,
}) => {
  const { t } = useTranslation('product');
  const rating = Number(summationOfRatings) / Number(numberOfReviews);
  const roundedSingleDecimal = Math.round(rating * 10) / 10;

  return (
    <span className={style.rating}>
      <span className="whitespace-nowrap" title={t('product:rating.stars', { count: roundedSingleDecimal, total: 5 })}>
        <StarRating rating={rating} color="text-secondary" className={style.ratingStar} />
      </span>
      <span className={style.ratingText} aria-hidden>
        {roundedSingleDecimal}
      </span>
      <span className={style.ratingText} aria-hidden>
        ({numberOfReviews})
      </span>
    </span>
  );
};

const CardImage: FC<{ images: ProductImage[]; imgProps?: ImageOptions }> = ({ images, imgProps }) => {
  const imageSize: ImageSize = {
    xs: 'xxxs',
    sm: 'xxs',
    original: 'small',
  };

  if (images.length === 0) {
    return null;
  }

  if (images.length === 1) {
    return <BigCommerceProductImage size={imageSize} image={images[0]} options={{ ...imgProps }} />;
  }

  return (
    <>
      {/* mobile */}
      <section className="block md:hidden relative w-full rounded-md">
        <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          loop
          breakpoints={{}}
          pagination={{
            clickable: true,
            bulletClass: style.bullet,
            bulletActiveClass: style.bulletActive,
            el: `.${style.pagination}`,
          }}
        >
          {images.map((image) => (
            <SwiperSlide key={image.url}>
              <BigCommerceProductImage size={imageSize} image={image} options={{ ...imgProps }} />
            </SwiperSlide>
          ))}

          <div className={style.pagination} />
        </Swiper>
      </section>
      {/* tablet/desktop */}
      <div className={style.imageWrapper}>
        <BigCommerceProductImage
          size={imageSize}
          image={images[0]}
          options={{ ...imgProps, className: cn('rounded-md', style.mainImage) }}
        />
        <BigCommerceProductImage
          size={imageSize}
          image={images[1]}
          options={{ ...imgProps, className: cn('rounded-md', style.flickImage) }}
        />
      </div>
    </>
  );
};

// new ProductCard for PLP
// TBD on how to integrate ATC button (for non PLP product cards)
const ProductCard: FC<Props> = ({
  className,
  product,
  imgProps,
  showProductDescriptionMobile = false,
  showProductDescriptionDesktop = false,
  onAddToCart,
  ...rest
}) => {
  const router = useRouter();
  const { t } = useTranslation(['common', 'product']);
  const hasL360PremiumInCart = useHasL360PremiumInCart();

  const { name, defaultImage, description, lifestyleImages, badge, pricing, currencyCode } = getProductInfo(product);

  const hasReview = product.reviewSummary?.numberOfReviews !== 0;
  const updatedQuery = cleanQueryParams(router.query);
  const queryParams = { ...updatedQuery, slug: product.slug };
  const shouldRenderDescription = showProductDescriptionDesktop || showProductDescriptionMobile;

  const showAddToCardBtn = useMemo(
    () => onAddToCart && product.inventory.isInStock && !hasL360PremiumInCart,
    [onAddToCart, product.inventory.isInStock, hasL360PremiumInCart]
  );

  const atcClicked = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (onAddToCart && !hasL360PremiumInCart) {
        onAddToCart(product.id, product.sku, product.name, product.type as ProductType, undefined, product.brand);
      }
    },
    [onAddToCart, product, hasL360PremiumInCart]
  );

  return (
    <Link href={{ pathname: '/product/[slug]', query: queryParams }} prefetch={false} legacyBehavior>
      {/* See: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/no-static-element-interactions.md#case-the-event-handler-is-only-being-used-to-capture-bubbled-events */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={cn(style.container, className)}
        onMouseDown={() => trackEvent({ action: name, category: 'Product Selection', label: 'Product Card' })}
        {...rest}
      >
        <BadgeOverlay
          className={style.imageContainer}
          shift={{ top: '8px', bottom: 0, left: 0, right: '8px' }}
          TopRight={badge && <Badge {...badge} className={style.badge} />}
        >
          <CardImage
            images={[defaultImage, lifestyleImages[0]].filter((img): img is ProductImage => !!img?.url)}
            imgProps={imgProps}
          />
          {showAddToCardBtn && (
            <button className={cn(style.addToCart)} type="button" onClick={atcClicked}>
              <Text
                variant="text-4"
                asElement="span"
                className="flex items-center bg-white text-primary p-xs h-full"
                color="var(--text-primary)"
              >
                {t('common:addToCart')} <span className={style.separator} />
                <span className={cn(style.faAtc, 'text-black-400')}>
                  <FontAwesomeIcon icon={faShoppingCart} />
                </span>
              </Text>
            </button>
          )}
        </BadgeOverlay>
        <div className={style.contentWrapper}>
          <Link href={{ pathname: '/product/[slug]', query: queryParams }} className={style.name}>
            {name}
          </Link>
          {description && shouldRenderDescription && (
            <Text
              variant="xsmall"
              color="var(--text-primary)"
              className={getDescriptionClassName(showProductDescriptionMobile, showProductDescriptionDesktop)}
            >
              {description}
            </Text>
          )}
          {hasReview && <Rating {...product.reviewSummary} />}
          <Pricing
            {...pricing}
            className="justify-center md:justify-start"
            currencyCode={currencyCode}
            textClassName={style.price}
            subTextClassName={style.originalPrice}
            discountTextClassName={style.discount}
            hideRegPriceLabel
          />
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
