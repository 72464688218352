import { useRouter } from 'next/router';
import { i18n } from 'next-i18next.config';

import { hasLife360Membership } from '@framework/api/utils/cart/validation';
import useCart from '@framework/cart/use-cart';

export const useHasL360PremiumInCart = (): boolean => {
  const { data: cart } = useCart();
  const { locale = i18n.defaultLocale } = useRouter();

  return hasLife360Membership(cart?.lineItems || [], locale);
};
