import { Hyperlink as ContentfulHyperlink, INLINES } from '@contentful/rich-text-types';
import { FC } from 'react';

import { RichTextRenderer } from '@components/common/RichText/factory';
import { Link } from '@components/ui';
import { getTargetByLink } from '@lib/check-external-link';

interface Props {
  href: string;
  text: string;
}

const Hyperlink: FC<Props> = ({ href, text }) => (
  <Link href={href} target={getTargetByLink(href)}>
    {text}
  </Link>
);

const mapper = (node: ContentfulHyperlink): Props => ({
  href: node.data.uri,
  text: node.content[0].value,
});

export default RichTextRenderer({ name: INLINES.HYPERLINK, mapper })(Hyperlink);
