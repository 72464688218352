import { Entry } from 'contentful';
import { FC } from 'react';

import style from '@components/blogPage/BlogDetail/BlogDetail.module.scss';
import { RichTextRenderer } from '@components/common/RichText/factory';
import { mapFields } from '@components/common/RichText/helpers';
import { UnwrapEntry } from '@components/common/RichText/types';
import { ModuleProduct } from '@components/common/types/ModuleProduct';
import { Button } from '@components/ui';
import { useAddToCart } from '@lib/hooks/useAddToCart';
import { useHasL360PremiumInCart } from '@lib/hooks/useHasL360PremiumInCart';

type RendererEntryProps = Entry<{
  internalName: string;
  buttonCta: string;
  product: Entry<ModuleProduct>;
}>;

type Props = UnwrapEntry<RendererEntryProps>;

const BlogCta: FC<Props> = ({ buttonCta, product }: Props) => {
  const [loading, onAddToCart] = useAddToCart();
  const { sku, name: productName, bigCommerceId: productId } = product;
  const hasL360PremiumInCart = useHasL360PremiumInCart();

  return hasL360PremiumInCart ? null : (
    <Button
      className={style.button}
      variant="cta"
      onClick={() => onAddToCart(productId.toString(), sku, productName)}
      aria-label={buttonCta}
      disabled={loading}
      loading={loading}
    >
      {buttonCta}
    </Button>
  );
};

const mapper = (entry: RendererEntryProps) => mapFields(entry);

export default RichTextRenderer({ name: 'blogCta', mapper })(BlogCta);
