import { Entry } from 'contentful';
import { Fragment } from 'react';

import genRandomId from '@lib/random-id';

import { UnwrapEntry } from './types';

export function getTextComponentWithBreakLines(text: string) {
  return text.split('\n').reduce(
    (InnerComponent, innerText, i) => (
      <Fragment key={genRandomId()}>
        {InnerComponent}
        {i > 0 && <br />}
        {innerText}
      </Fragment>
    ),
    <></>
  );
}

export function mapFields<T>(entry: Entry<T>): UnwrapEntry<Entry<T>> {
  return deepMap(entry as any, {}) as any;
}

// TODO: handle arrays
function deepMap<T extends Record<any, any>>(object: Entry<T>, unwrappedObject: Record<any, any>): T {
  const { fields } = object;
  Object.keys(fields).forEach((key) => {
    if (isAnEntry(fields[key])) {
      // eslint-disable-next-line no-param-reassign
      unwrappedObject[key] = deepMap(fields[key] as Entry<T>, {});
    } else {
      // eslint-disable-next-line no-param-reassign
      unwrappedObject[key] = fields[key];
    }
  });

  return unwrappedObject;
}

function isAnEntry<T>(entry: Record<string, any>): entry is Entry<T> {
  return entry.fields && entry.metadata && entry.sys;
}
